import { createNamespacedHelpers } from 'vuex';
import { GET_CUSTOMERS } from '@/core/services/store/groupDashboard.module';
const { mapActions } = createNamespacedHelpers('groupDashboard');

export const commonTableMixin = {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    tabFilter: {
      type: [Number, String],
      default: null,
    },
    isInprogress: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      // Filters
      filterCustomer: '',
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      sort: {
        by: null,
        order: null,
      },

      // Data
      items: [],
    };
  },

  watch: {
    'paging.page'() {
      this.getCustomers();
    },
  },

  beforeDestroy() {
    this.resetFilters();
  },

  methods: {
    ...mapActions({ GET_CUSTOMERS }),

    onSearchCustomer(val) {
      this.filterCustomer = val;
      this.getCustomers();
    },

    handleSort(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.getCustomers();
    },

    reFecthCustomers() {
      this.resetFilters();
      this.getCustomers();
    },

    async getCustomers() {
      const params = {
        ...this.$getPagination(this.paging, this.sort),
        coachId: this.filters.coach?.id,
        trainingGroupId: this.filters.group?.id,
        isPackageExpired: !this.isInprogress,
      };
      if (this.filterCustomer) params.patientName = this.filterCustomer;
      if (this.isInprogress) params.tabFilter = this.tabFilter;

      if (!params.trainingGroupId) {
        return (this.items = []);
      }

      const { total, items, error } = await this.GET_CUSTOMERS(params);
      if (error) return;

      this.paging.total = total;
      this.items = items;
    },

    resetFilters() {
      this.filterCustomer = '';
      this.items = [];

      this.paging.page = 1;
      this.paging.pageSize = 10;
      this.paging.total = 0;

      this.sort.by = null;
      this.sort.order = null;
    },
  },
};
