<template>
  <div class="py-4">
    <b-row>
      <b-col cols="4">
        <basic-input
          :value.sync="filterCustomer"
          :debounce="1000"
          name="searchText"
          label="Tên khách hàng"
          placeholder="Nhập tên khách hàng"
          @v-modal:update="onSearchCustomer"
        />
      </b-col>
    </b-row>

    <template-table
      :column="column"
      :data="items"
      :paging="paging"
      :tableAction="false"
      :selectAction="false"
      :searchAction="false"
      @sortBy="handleSort"
    >
      <template v-slot:body="{ item }">
        <td>
          <div class="d-flex align-items-center">
            <avatar
              :text="item.fullName"
              :src="item.image && item.image.url"
              :rounded="true"
              :class="{
                'user-priority': isSupportPrority(item.supportType),
              }"
              size="40px"
            />
            <div class="d-flex flex-column ml-5">
              <p class="mb-0">
                <a class="a-link" @click="goToUserDashboard(item)">
                  {{ item.fullName }}
                </a>
              </p>
              <p class="mb-0">
                {{ item.packageName }}
              </p>
            </div>
          </div>
        </td>
        <td style="width: 20px">
          <action-dropdown
            :value="item"
            :show_copy="false"
            :show_view="false"
            :show_edit="false"
            :show_delete="false"
            :boundary="items.length > 2 ? 'scrollParent' : 'window'"
          >
            <template>
              <b-dropdown-text
                tag="div"
                class="navi-item cursor-pointer"
                @click="onClickAction(item, 'UserDashboard')"
              >
                <a class="navi-link text-primary">
                  <b-icon-grid class="mr-2" scale="1.2" />
                  <span class="navi-text text-primary">Xem dashboard user</span>
                </a>
              </b-dropdown-text>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-text
                tag="div"
                class="navi-item cursor-pointer"
                @click="onClickAction(item, 'UserApp')"
              >
                <a class="navi-link text-primary">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/user-info.svg"
                    />
                  </span>
                  <span class="navi-text text-primary">
                    Xem chi tiết user app
                  </span>
                </a>
              </b-dropdown-text>
              <b-dropdown-divider
                v-if="
                  item.coach11NextDating &&
                  (isWriteAllPermission || isWriteOwnerPermission)
                "
              ></b-dropdown-divider>
              <b-dropdown-text
                v-if="
                  item.coach11NextDating &&
                  (isWriteAllPermission || isWriteOwnerPermission)
                "
                tag="div"
                class="navi-item cursor-pointer"
                @click="onClickAction(item, 'Implement11')"
              >
                <a class="navi-link text-primary">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/settings.svg"
                    />
                  </span>
                  <span class="navi-text text-primary">Thực hiện lịch 1-1</span>
                </a>
              </b-dropdown-text>
              <b-dropdown-divider
                v-if="
                  item.isBooking11 &&
                  (isWriteAllPermission || isWriteOwnerPermission)
                "
              ></b-dropdown-divider>
              <b-dropdown-text
                v-if="
                  item.isBooking11 &&
                  (isWriteAllPermission || isWriteOwnerPermission)
                "
                tag="div"
                class="navi-item cursor-pointer"
                @click="onClickAction(item, 'Book11')"
              >
                <a class="navi-link text-primary">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Time/calendar.svg"
                    />
                  </span>
                  <span class="navi-text text-primary">Đặt lịch 1-1</span>
                </a>
              </b-dropdown-text>
              <b-dropdown-divider
                v-if="
                  item.coach1NNextDating &&
                  (isWriteAllPermission || isWriteOwnerPermission)
                "
              ></b-dropdown-divider>
              <b-dropdown-text
                v-if="
                  item.coach1NNextDating &&
                  (isWriteAllPermission || isWriteOwnerPermission)
                "
                tag="div"
                class="navi-item cursor-pointer"
                @click="onClickAction(item, 'Implement1N')"
              >
                <a class="navi-link text-primary">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Basic/settings.svg"
                    />
                  </span>
                  <span class="navi-text text-primary">Thực hiện lịch 1-n</span>
                </a>
              </b-dropdown-text>
              <b-dropdown-text
                v-if="
                  item.isBooking1N &&
                  (isWriteAllPermission || isWriteOwnerPermission)
                "
                tag="div"
                class="navi-item cursor-pointer"
                @click="onClickAction(item, 'Book1N')"
              >
                <a class="navi-link text-primary">
                  <span class="menu-icon svg-icon svg-icon-sm">
                    <inline-svg
                      class="svg-icon"
                      src="/media/svg/icons/Neolex/Time/calendar.svg"
                    />
                  </span>
                  <span class="navi-text text-primary">Đặt lịch 1-n</span>
                </a>
              </b-dropdown-text>
            </template>
          </action-dropdown>
        </td>
        <td class="text-center">
          {{ item.weekInRoadmap }}/{{ item.weekRoadmap }}
        </td>
        <td>
          <span v-html="getBooking(item.coach11NextDating)"></span>
          <a
            v-if="
              item.coach11NextDating &&
              (isWriteAllPermission || isWriteOwnerPermission)
            "
            href="javascript:void(0)"
            class="d-block a-link"
            @click="goTo11Appt(item)"
          >
            Thực hiện
          </a>
          <a
            v-if="
              item.isBooking11 &&
              (isWriteAllPermission || isWriteOwnerPermission)
            "
            href="javascript:void(0)"
            class="d-block a-link"
            @click="openModalCalendar('coach11', item)"
          >
            Đặt lịch
          </a>
        </td>
        <td>
          <template>
            <span v-html="getBooking(item.coach1NNextDating)"></span>
            <a
              v-if="
                item.coach1NNextDating &&
                (isWriteAllPermission || isWriteOwnerPermission)
              "
              href="javascript:void(0)"
              class="d-block a-link"
              @click="goTo1NAppt(item)"
            >
              Thực hiện
            </a>
            <a
              v-if="
                item.isBooking1N &&
                (isWriteAllPermission || isWriteOwnerPermission)
              "
              href="javascript:void(0)"
              class="d-block a-link"
              @click="openModalCalendar('coach1N', item)"
            >
              Đặt lịch
            </a>
          </template>
        </td>
        <td>{{ getActivedDate(item.lastActiveDate) }}</td>
        <td>
          <span
            :class="['study', getStudyProgressStyle(item.studyProgressType)]"
          >
            {{ getStudyProgress(item.studyProgressType) }}
          </span>
        </td>
        <td>
          <span
            :class="[
              'support',
              {
                'support--prority': isSupportPrority(item.supportType),
              },
            ]"
          >
            {{ getSupportTypeName(item.supportType) }}
          </span>
        </td>
      </template>
    </template-table>

    <calendar-schedule-form
      type-form="CREATE"
      :isCoach11="isCoach11"
      :isCoach1N="isCoach1N"
      :filter-group="filterGroup"
      :prop-patient="selectedPatient"
      @save="callbackSaveSchedule"
      @hide="restModalCalendar"
    />
  </div>
</template>

<script>
import { tableMixin } from './../mixins/TableInprogress';
import { commonTableMixin } from './../mixins/CommonTable';
import { createNamespacedHelpers } from 'vuex';
import { CREATE_CALENDER } from '@/core/services/store/calender.module';
const { mapActions: calendarActions } = createNamespacedHelpers('calender');

export default {
  name: 'TableInprogress',

  components: {
    CalendarScheduleForm: () =>
      import('@/view/pages/calendar/CalendarScheduleForm.vue'),
  },

  mixins: [tableMixin, commonTableMixin],

  props: {
    filters: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      // Some data from tableMixin and commonTableMixin
      selectedPatient: {},

      isCoach11: false,
      isCoach1N: false,
    };
  },

  computed: {
    filterGroup() {
      return {
        coachId: this.filters.coach?.id,
        trainingGroupId: this.filters.group?.id,
      };
    },
    isWriteAllPermission() {
      return this.$route.meta.isWriteAllPermission;
    },
    isWriteOwnerPermission() {
      return this.$route.meta.isWriteOwnerPermission;
    },
  },

  methods: {
    // Some functions from tableMixin and commonTableMixin
    ...calendarActions({ CREATE_CALENDER }),

    openModalCalendar(type, item) {
      if (type === 'coach11') {
        this.isCoach11 = true;

        this.selectedPatient = {
          fullName: item.fullName,
          accountId: item.accountId,
        };
      } else {
        this.isCoach1N = true;
      }
      this.$bvModal.show('calendar-schedule-add-modal');
    },

    callbackSaveSchedule: async function (event = {}) {
      const { data } = event;

      try {
        await this.CREATE_CALENDER(data);
        this.$bvModal.hide('calendar-schedule-add-modal');
        this.$emit('save');
      } catch (error) {
        console.error(error);
      }
    },

    restModalCalendar() {
      this.isCoach11 = false;
      this.isCoach1N = false;
    },

    getBooking(calendar) {
      if (!calendar) return null;

      return `<div class='text-success'>
        <span>${this.formatDateLocale(calendar.appointmentDate * 1000)}</span>
      <br />
      <span>(${this.getTextDuration(
        calendar.appointmentDate,
        calendar.appointmentDate + calendar.duration,
      )})</span>
      </div>`;
    },
    getTextDuration(timeStart, timeEnd) {
      return `${this.getTimeOfDate({
        date: timeStart * 1000,
      })} - ${this.getTimeOfDate({ date: timeEnd * 1000 })}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-priority {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-image: url(/media/users/alert-circle.svg);
    height: 40px;
    width: 40px;
    left: 30px;
    top: -6px;
    z-index: 1;
  }
}

.a-link,
.a-link:hover {
  cursor: pointer;
  text-decoration: underline !important;
}

.text-success {
  color: #21a567 !important;
}

.support {
  display: block;
  padding: 6px 12px;
  border-radius: 24px;
  background-color: #21a567;
  text-align: center;
  color: #ffffff;

  &--prority {
    background-color: #f58220;
  }
}

.study {
  font-weight: bold;

  &--improve {
    color: #e53935;
  }
  &--improving {
    color: #f58220;
  }
  &--right {
    color: #01645a;
  }
  &--good {
    color: #50c087;
  }
  &--perfect {
    color: #21a567;
  }
  &--pending {
    color: #454649;
  }
}
</style>
